import { WEIGHT } from 'helpers/const/views';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import DrillerGauge from 'pages/details/charts/DrillerGauges';
import WeightMeter from 'pages/details/charts/WeightMeter';
import DetailsCard from 'pages/details/DetailsCard';
import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled(DetailsCard)`
	height: auto;
`;
export default function WeightChart() {
	const { data }: any = useRigDetail();

	const [titleConst, setTitleConst] = React.useState('Weight On Hook');
	const [view, setView] = React.useState(WEIGHT.HOOK);
	const changeView = React.useCallback((type) => setView(type), []);
	const drillerSide = parseInt(data?.driller_side, 10);
	const offdrillerSide = parseInt(data?.offdriller_side, 10);

	const drillerSideVal = parseInt(data?.driller_side_psi, 10);
	const offdrillerSideVal = parseInt(data?.offdriller_side_psi, 10);

	const offdrillerSidePSI = offdrillerSideVal > 5000 ? 5000 : offdrillerSideVal;
	const drillerSidePSI = drillerSideVal > 5000 ? 5000 : drillerSideVal;

	const weight = parseInt(data?.actualLoad, 10);
	const bew = parseInt(data?.rig_bew, 10);
	const totalWeight = bew + weight;
	// const wob = parseInt(data?.rig_wob, 10);

	let weightOnHook = weight - bew;
	if (weightOnHook < 0) {
		weightOnHook = 0;
	}
	if (weight > 300) {
		weightOnHook = 300;
	}

	const handleTitle = (titl, v) => {
		setTitleConst(titl);
		changeView(v);
	};

	const weightMax =
		parseInt(data?.weight_max || '0', 10) > 999
			? parseInt(data?.weight_max || '0', 10) / 1000
			: parseInt(data?.weight_max || '0', 10);

	return (
		<Wrapper
			className="cards cards-full-height"
			title={
				<DropdownButton title={titleConst} className="weight-on-hook">
					<Dropdown.Item
						key="hook"
						onClick={() => handleTitle('Weight On Hook', WEIGHT.HOOK)}
					>
						<span>Weight On Hook</span>
					</Dropdown.Item>
					<Dropdown.Item
						key="total"
						onClick={() => handleTitle('Total Weight', WEIGHT.TOTAL)}
					>
						<span>Total Weight</span>
					</Dropdown.Item>
					{/* <Dropdown.Item
						key="bit"
						onClick={() => handleTitle('Weight On Bit', WEIGHT.BIT)}
					>
						<span>Weight On Bit</span>
					</Dropdown.Item> */}
				</DropdownButton>
			}
			object={
				<span className="font-semibold">
					Weight Source: {data?.weight_source}
				</span>
			}
		>
			<div className="flex items-center w-full justify-evenly">
				<div>
					<DrillerGauge
						min={0}
						max={drillerSide}
						value={drillerSidePSI < 0 ? 0 : drillerSidePSI}
						title=""
					/>

					<div className="text-[0.875rem]">Driller Side</div>
				</div>
				<div>
					{view === WEIGHT.HOOK ? (
						<>
							<WeightMeter value={weightOnHook} maxValue={weightMax} />
							<div className="text-[0.875rem] mt-2">
								Dead Weight: {bew} Klbs
							</div>
						</>
					) : (
						<>
							<WeightMeter value={totalWeight} maxValue={weightMax} />
							<div className="text-[0.875rem] mt-2">
								Dead Weight: {bew} Klbs
							</div>
						</>
					)}
				</div>
				<div>
					<DrillerGauge
						min={0}
						max={offdrillerSide}
						value={offdrillerSidePSI < 0 ? 0 : offdrillerSidePSI}
						title=""
					/>

					<div className="text-[0.875rem] mt-1">Offdriller Side</div>
				</div>
			</div>
		</Wrapper>
	);
}
