import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { removeToken } from 'helpers/utils/auth-provider';
import toast from 'react-hot-toast';
import { apiURL } from './urlDataBuilders';

const errorLink = onError(
	({ graphQLErrors, networkError, forward, operation }) => {
		if (graphQLErrors) {
			graphQLErrors.forEach((err) => {
				if (err.code === 'UNAUTHENTICATED') {
					toast.error(
						'You’ve been logged out due to a new login elsewhere. Redirecting to login for security.',
					);
					removeToken();
					setTimeout(() => {
						window.location.reload('/');
					}, 500);
				}
			});
		}
		if (networkError) console.error(`[Network error]: ${networkError}`);
		return forward(operation);
	},
);
const httpLink = new HttpLink({ uri: apiURL() });
const client = new ApolloClient({
	link: from([errorLink, httpLink]),
	cache: new InMemoryCache({
		addTypename: false, // It will remove __typename from all queries and mutation response.
	}),
});

export default client;
